import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import clinicsMap from "../../content/przychodnie.yaml"
import SEO from "../components/layout/seo"
import Figure from "../components/figure"
import DoctorCard from "../components/doctor-card"
import { StaticImage } from "gatsby-plugin-image"

export default function Clinics({ data }) {
  const {
    title,
    page_title,
    page_description,
    fields,
    type,
    clinics
  } = data.page

  let doctorsMap = {}
  data.doctors.edges.map(({ node }) => doctorsMap[node.fields.slug.replace(/\//g, "")] = node)

  const seo = {
    title: page_title,
    description: page_description,
    url: 'https://medicusdukla.pl/poradnie/' + fields.slug
  }

  const typesMap = {
    'podstawowa-opieka-zdrowotna': "Podstawowa opieka zdrowotna",
    'poradnie-specjalistyczne': "Poradnie specjalistyczne",
    'poradnie-specjalistyczne-prywatne': "Poradnie specjalistyczne prywatne",
  }

  const onlyOneClinic = clinics.length === 1;

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />

      <div className="o-clinics">
        <div className="o-clinics__hero" data-g="container grid vertical-center">
          <div data-g="4 6@md">
            <span className="header-overtext">{typesMap[type]}</span>
            <h1 className="a-heading-medium">{onlyOneClinic ? clinics[0].title : title}</h1>
            {/*<a className="a-button" href="TODO" target="_blank" rel="nofollow">*/}
            {/*  Umów wizytę*/}
            {/*</a> */}
            <br/>
            <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz"/>
          </div>
          <div data-g="4 6@md">
            <StaticImage className="hero-img a-img" src="../images/clinics/hero.png" alt="poradnie" formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>
        {clinics.map((clinic, key) => (
          <div data-g="container">
            {!onlyOneClinic && <h2 className="a-heading-medium">{clinic.title}</h2>}
            <div dangerouslySetInnerHTML={{__html: clinic.webzona}}/>
            <Figure src="/images/diagnostyka/localization.svg" text={clinicsMap[clinic.id].place} />
            <div data-g="grid">
              {clinic.doctors.length > 0 &&  clinic.doctors.map((doctor, subkey) => {
                const doc = doctorsMap[doctor.id]
                return (
                  <div data-g={`4 6@md 5@xl ${subkey % 2 === 1 && 'offset-7@xl'}`} key={subkey}>
                    <DoctorCard
                      initials={doc.initials}
                      role={doc.specialization}
                      name={doc.name}
                      phone={doctor.phone}
                      mail={doctor.mail}
                      when={doctor.when}
                      when_multiple={doctor.when_multiple}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query PoradniePost($slug: String) {
        page: poradnieYaml(fields: {slug: { eq: $slug }}) {
            title
            page_title
            type
            page_description
            fields {
                slug
            }
            clinics {
                id
                title
                webzona
                doctors {
                    id
                    when
                    when_multiple {
                        key
                        value
                    }
                }
            }
        }
        doctors: allLekarzeYaml {
            edges {
                node {
                    name
                    specialization
                    initials
                    fields {
                        slug
                    }
                }
            }
        }
    }`
